import React from 'react'
import Layout from '../components/Layout'

const Definition = ({ location }) => {
  return (
    <Layout location={location} lang={'ca'}>
      <section id="one">
        <header className="major">
          <h2>Sessions i tallers d’estimulació musical en família</h2>
          <h4>De 0 a 3 anys</h4>
        </header>
        <p>
          La principal font de comunicació durant els primers anys de vida de l’infant és bàsicament el món sonor, és per això que l’estimulació musical es converteix en un eina clau per a promoure el seu desenvolupament i acompanyar-los en el procés de descoberta d’un mateix i de l’entorn, de la pròpia veu i dels pares, del propi cos, de l’escolta, de la imitació i la creació, de la descoberta dels instruments.
        </p>
        <p>
          Durant aquestes sessions setmanals i/o tallers es generarà un espai per compartir i enfortir el vincle entre els pares/mares-fills a partir de vivències musicals. Es durà a terme una estimulació sensorial global de l’infant a partir de cançons, els sons, materials diversos, instruments, audicions, el moviment, danses, jocs de falda, la improvisació i la relaxació.
        </p>
      </section>
      <section id="two">
        <h2>Objectius</h2>
        <p>
          <ul>
            <li>Gaudir en família de les propostes presentades</li>
            <li>Fomentar l’atenció, la concentració i la memòria</li>
            <li>Explorar els diferents instruments i materials que es presenten i el so que generen</li>
            <li>Potenciar la coordinació oculo-manual</li>
            <li>Fomentar la capacitat motora grossa i fina</li>
            <li>Estimular la psicomotricitat de l’infant</li>
            <li>Sincronitzar moviments a una font externa (peça de música)</li>
            <li>Enfortir l’autoestima, la seguretat en si mateix i l’autoconfiança</li>
            <li>Enfortir el vincle família-infant</li>
            <li>Fomentar les habilitats conductuals, socials i de comunicació dels infants</li>
          </ul>
        </p>
      </section>
      <section id="three">
        <h2>Propers tallers</h2>
        <p>
          Per consultar els tallers d'estimulació musical que es realitzaran properament,
          <a
            className="button special small"
            style={{ marginLeft: '15px' }}
            href='/events'
          >
            clica aquí!
          </a>
        </p>
      </section>
    </Layout>
  )
};

export default Definition;
